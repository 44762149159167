export const register_fp = async (id?: string): Promise<void> => {
  if (id) {
    return;
  }

  const [
    { defaultScriptUrlPattern: DEFAULT_SCRIPT_URL_PATTERN, load },
    { actions },
    { FINGERPRINT_API_KEY },
    { link },
  ] = await Promise.all([
    import("@fingerprintjs/fingerprintjs-pro"),
    import("astro:actions"),
    import("astro:env/client"),
    import("astro-typed-links/link"),
  ]);

  const fp = await load({
    apiKey: FINGERPRINT_API_KEY,
    scriptUrlPattern: [
      link("/api/fp/", {
        searchParams: Object.fromEntries(
          ["version", "apiKey", "loaderVersion"].map((key) => [key, `<${key}>`])
        ),
      }),
      DEFAULT_SCRIPT_URL_PATTERN,
    ],
    region: "ap",
    endpoint: "https://metrics.saturdayquiztime.com.au",
  });

  const { visitorId } = await fp.get();
  await actions.auth.set_fp_id({ fingerprint_id: visitorId });
};
